import z from 'zod'

export const APP_NAME = 'Proven Valor'

// TODO tests
export const AWS_PROFILE_PHOTOS_FOLDER = 'member-photos'
export const AWS_DISCOUNT_PHOTOS_FOLDER = 'discount-photos'

// Discounts
export const DISCOUNT_CATEGORIES = [
	{ label: 'Food and Beverages', value: 'food' },
	{ label: 'Fashion', value: 'fashion' },
	{ label: 'Electronics', value: 'electronics' },
	{ label: 'eCommerce', value: 'ecommerce' },
] as const

// CRM statuses
export const VETERAN_STATUS_VERIFIED = 'verified'
export const VETERAN_STATUS_MISSING_DD214 = 'missing_dd214' // CRM member found, docs found, but not a DD214
export const VETERAN_STATUS_NO_DOCUMENTS = 'no_documents' // CRM member found, but no docs
export const VETERAN_STATUS_NOT_FOUND = 'not_found' // CRM couldn't find that member or the found order is already linked
export const VETERAN_UNVERIFIED_STATUSES = [
	VETERAN_STATUS_MISSING_DD214,
	VETERAN_STATUS_NO_DOCUMENTS,
	VETERAN_STATUS_NOT_FOUND,
] as const

// Unable to process
export const UNABLE_TO_PROCESS = 'unable_to_process'

// CRM meta-statuses
export const ADMIN_VETERAN_STATUS_PENDING = 'document_sync_not_occurred' // We don't have any CrmResponse records yet
export const ADMIN_VETERAN_SELF_SYNC_FAILED_MULTIPLE_TIMES =
	'document_self_sync_failed_multiple_times' // The user has failed to sync their documents

// Membership Card status
export const MEMBERSHIP_CARD_STATUS_WAITING_FOR_MEMBER_CONFIRMATION =
	'waiting_for_member_confirmation'
export const MEMBERSHIP_CARD_MEMBER_REQUESTED_EDITS = 'member_requested_edits'
export const MEMBERSHIP_CARD_STATUS_READY_TO_SEND_TO_ENGRAVER =
	'ready_to_send_to_engraver'
export const MEMBERSHIP_CARD_STATUS_PROCESSING = 'processing'
export const MEMBERSHIP_CARD_STATUS_SENT_TO_ENGRAVER = 'sent_to_engraver'
export const MEMBERSHIP_CARD_STATUS_SHIPPED = 'shipped'
export const MEMBERSHIP_CARD_STATUS_DELIVERED = 'delivered'
export const MEMBERSHIP_CARD_STATUSES = [
	MEMBERSHIP_CARD_MEMBER_REQUESTED_EDITS,
	MEMBERSHIP_CARD_STATUS_WAITING_FOR_MEMBER_CONFIRMATION,
	MEMBERSHIP_CARD_STATUS_READY_TO_SEND_TO_ENGRAVER,
	MEMBERSHIP_CARD_STATUS_PROCESSING,
	MEMBERSHIP_CARD_STATUS_SENT_TO_ENGRAVER,
	MEMBERSHIP_CARD_STATUS_SHIPPED,
	MEMBERSHIP_CARD_STATUS_DELIVERED,
] as const
export const BATCH_STATUSES = [
	MEMBERSHIP_CARD_STATUS_READY_TO_SEND_TO_ENGRAVER,
	MEMBERSHIP_CARD_STATUS_PROCESSING,
	MEMBERSHIP_CARD_STATUS_SENT_TO_ENGRAVER,
	MEMBERSHIP_CARD_STATUS_SHIPPED,
	MEMBERSHIP_CARD_STATUS_DELIVERED,
] as const
export const MEMBERSHIP_CARD_STATUSES_CAN_EDIT_PROFILE = [
	MEMBERSHIP_CARD_MEMBER_REQUESTED_EDITS,
	MEMBERSHIP_CARD_STATUS_WAITING_FOR_MEMBER_CONFIRMATION,
] as const

export const MEMBERSHIP_CARD_NUMBER_LENGTH = 8

// Billing subscriptions
export const BILLING_SUBSCRIPTION_STATUS_ACTIVE = 'active'
export const BILLING_SUBSCRIPTION_STATUS_CANCELED = 'canceled'
export const BILLING_SUBSCRIPTION_STATUS_NO_SUBSCRIPTION = 'no_subscription'
export const BILLING_SUBSCRIPTION_STATUSES = [
	BILLING_SUBSCRIPTION_STATUS_ACTIVE,
	BILLING_SUBSCRIPTION_STATUS_CANCELED,
	BILLING_SUBSCRIPTION_STATUS_NO_SUBSCRIPTION,
] as const

// CRM
export const CRM_DOCUMENT_TYPE_DD214 = 'DD214'
export const CRM_DOCUMENT_TYPE_OMPF = 'OMPF'
export const CRM_DOCUMENT_TYPE_POINTS_STATEMENT = 'POINTS_STATEMENT'
export const CRM_DOCUMENT_TYPES = [
	CRM_DOCUMENT_TYPE_DD214,
	CRM_DOCUMENT_TYPE_OMPF,
	CRM_DOCUMENT_TYPE_POINTS_STATEMENT,
] as const
export const CRM_UPLOADS_FOLDER = 'uploads'

// Documents
export const DOCUMENT_TYPE_DD214 = 'dd214'
export const DOCUMENT_TYPE_OMPF = 'ompf'
export const DOCUMENT_TYPE_POINTS_STATEMENT = 'points_statement'
export const DOCUMENT_TYPES = [
	{
		label: 'DD214',
		value: DOCUMENT_TYPE_DD214,
		description: 'Discharge Documents - DD214, NGB-22, or Equivalent',
	},
	{
		label: 'OMPF',
		value: DOCUMENT_TYPE_OMPF,
		description: 'OMPF - Official Military Personnel File',
	},
	{
		label: 'Points Statement',
		value: DOCUMENT_TYPE_POINTS_STATEMENT,
		description: 'POINTS',
	},
] as const

export const DOCUMENT_REDACTION_REQUIRED = 'redaction_required'
export const DOCUMENT_NO_PUBLIC_DD214 = 'no_public_dd214'
export const DOCUMENT_STATUS_HAS_DD214 = 'has_dd214'

export const DOCUMENT_FILTER_OPTIONS = [
	{ label: 'No Public DD214', value: DOCUMENT_NO_PUBLIC_DD214 },
	{ label: 'Redaction Required', value: DOCUMENT_REDACTION_REQUIRED },
	{ label: 'Has DD214', value: DOCUMENT_STATUS_HAS_DD214 },
	{ label: 'Unable to Process', value: UNABLE_TO_PROCESS },
] as const

// Stripe / Subscriptions
export const SUBSCRIPTION_PENDING_CONFIRMATION = 'pending_confirmation'
export const SUBSCRIPTION_INCOMPLETE = 'incomplete'
export const SUBSCRIPTION_ACTIVE = 'active'
export const SUBSCRIPTION_CANCELED = 'canceled'

// Roles
export const ROLE_USER = 'user'
export const ROLE_ADMIN = 'admin'
export const ROLES = [ROLE_USER, ROLE_ADMIN] as const
export const ROLE_ENUM = z.enum(ROLES)
export type Role = z.infer<typeof ROLE_ENUM>

// Military
export const DISCHARGE_CHARACTER_HONORABLE = 'honorable'
export const DISCHARGE_CHARACTER_GENERAL = 'general'
export const DISCHARGE_CHARACTER_OTHER_NO_LISTING = 'under_other_no_listing'

export const DISCHARGE_CHARACTERS = [
	DISCHARGE_CHARACTER_HONORABLE,
	DISCHARGE_CHARACTER_GENERAL,
	DISCHARGE_CHARACTER_OTHER_NO_LISTING,
] as const

export const DISCHARGE_CHARACTER_DROPDOWN_OPTIONS = [
	{ value: DISCHARGE_CHARACTER_HONORABLE, label: 'Honorable' },
	{
		value: DISCHARGE_CHARACTER_GENERAL,
		label: 'Under Honorable Conditions (General)',
	},
	{
		value: DISCHARGE_CHARACTER_OTHER_NO_LISTING,
		label: 'Other - No Listing',
	},
] as const

export const MAX_FILE_SIZE = 1024 * 1024 * 3 // 3MB

// ! Devs: this was a complete guess. Please adjust this number if we have memory / "cannot convert PDF to image - too large" errors in Production
export const MAX_PDF_PROCESSING_SIZE = 1024 * 1024 * 10 // 10MB
